<template>
    <cms-component v-bind="$props" :style="containerStyle" v-if="(component.isCollapsing() && collapsed) || (!component.isCollapsing() && !collapsed)"
                   :class="[`layout-${component.mediaProxy.layout}`, `size-${component.mediaProxy.linkSizeMode}`, {'fill-size': component.mediaProxy.fillSize, 'show-separator': component.mediaProxy.separator}]">
        <slot name="prepend"/>
        <cms-link
            v-for="(link, index) in links" :key="`link-${index}`" :link="link" class="btn btn-scheme cms-navigation-link" :in-main-menu="inMainMenu"
            @click.native="$emit('navigated', $event)" @collapse-nav-menu="$emit('collapse-nav-menu')"
            :class="[
              `btn-${component.mediaProxy.size}`,
              `btn-type-${component.mediaProxy.buttonType}`,
              `display-${component.mediaProxy.display}`,
              {'router-link-exact-active': isActive(link)}
            ]"
        >
            <i v-if="component.mediaProxy.display !== 'text'" class="icon fa-fw" :class="[link.glyph, `fa-${component.mediaProxy.iconSize}`, fontAwesomePrefix]"/>
            <span class="name" v-if="component.mediaProxy.display !== 'icon'">{{ tr(link.name, displayLanguage) }}</span>
        </cms-link>
        <slot name="append"/>
    </cms-component>
</template>

<script>
import CmsComponent from "@/components/editor/rendering/components/cms-component.vue";

// cms-component-navigation-links #hot-reload-debug
export default {
    name: `cms-component-navigation-links`,
    extends: CmsComponent,
    components: {CmsComponent},
    props: {
        inMainMenu: {type: Boolean},
        collapsed: {type: Boolean}
    },
    computed: {
        links() {
            if (this.component.useMenuNavigation) {
                return this.cmsWebsite.menu.links;
            } else {
                return this.component.links;
            }
        },
        containerStyle() {
            let style = {
                fontWeight: this.component.bold ? `bold` : ``,
                fontStyle: this.component.italic ? `italic` : `normal`,
                "--navigation-spacing": this.component.mediaProxy.spacing
            }
            if (this.component.mediaProxy.borderRadius && this.component.mediaProxy.borderRadius !== `var(--border-radius)`) {
                style[`--border-radius`] = this.component.mediaProxy.borderRadius;
            }
            if (this.component.mediaProxy.linkSizeMode === `custom`) {
                style[`--link-size`] = this.component.mediaProxy.linkSize;
            }
            if (this.component.mediaProxy.separator) {
                style[`--separator-color`] = this.component.mediaProxy.separatorColor;
                style[`--separator-width`] = this.component.mediaProxy.separatorWidth;
            }
            return style;
        }
    },
    methods: {
        isActive(link) {
            if (this.inMainMenu && link.name.en && link.name.fr) {
                const currentPath = this.$route.path;
                const enPath = `/${link.name.en.toLowerCase()}`;
                const frPath = `/${link.name.fr.toLowerCase()}`;
                return currentPath === enPath || currentPath === frPath;
            }
            return false;
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-component-navigation-links {
    display: flex;
    align-items: center;

    .btn {
        margin: var(--navigation-spacing);
        position: relative;
        display: inline-flex;
        align-items: center;
        min-height: 2.4em; // fix because line-height doesn't apply to flex without any text (icon only)

        &.display-text-icon-top {
            flex-direction: column;
            justify-content: center;

            .icon {
                margin-bottom: 0.25rem;
                line-height: inherit !important;
                vertical-align: auto !important;
            }
        }

        &.display-text-icon-left {
            .icon {
                margin-right: 0.25em;
            }
        }

        &.display-text-icon-right {
            .icon {
                order: 2;
                margin-left: 0.25em;
            }
        }

        &.display-icon {
            .icon {
                line-height: inherit !important;
                vertical-align: auto !important;
            }
        }
    }

    &.layout-vertical {
        flex-direction: column;

        .btn {
            display: flex;
            text-align: inherit;
        }

        &.size-custom {
            .btn {
                width: 100%;
                height: var(--link-size);
            }
        }

        &.fill-size {
            flex: 1;
            width: 100%;

            .btn {
                width: calc(100% - 2 * var(--navigation-spacing));
                margin-left: var(--navigation-spacing);
                margin-right: var(--navigation-spacing);
            }
        }

        &.show-separator {
            .btn {
                border-top: var(--separator-width) solid var(--separator-color);
            }
        }
    }

    &.layout-horizontal {
        flex-direction: row;

        &.size-justify {
            display: grid;
            grid-auto-flow: column;
            //grid-auto-columns: 1fr;
            grid-auto-columns: minmax(0, 1fr);

            .btn {
                justify-content: center;
            }
        }

        &.size-custom {
            .btn {
                width: var(--link-size);
                max-width: 100%;
            }
        }

        &.fill-size {
            flex: 1;
            height: 100%;

            .btn {
                height: 100%;
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &.show-separator {
            .btn + .btn:before {
                content: "";
                position: absolute;
                left: calc(var(--navigation-spacing) * -1);
                top: 0;
                width: 0;
                height: 100%;
                border-left: var(--separator-width) solid var(--separator-color);
            }
        }
    }
}
</style>
