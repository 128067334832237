<template>
    <cms-component v-bind="$props" :link="component.link" :style="containerStyle">
        <b-img class="image-component" :src="imageSrc" :style="imageStyle" :blank="!imageSrc" blank-color="#ddd" :alt="tr(component.alt)" @error="useFallback = true"/>
    </cms-component>
</template>

<script>
import CmsComponent from "@/components/editor/rendering/components/cms-component.vue";

// cms-component-image #hot-reload-debug
export default {
    name: `cms-component-image`,
    extends: CmsComponent,
    components: {CmsComponent},
    data() {
        return {
            useFallback: false
        }
    },
    computed: {
        imageSrc() {
            let src = this.component.languageProxy.src;
            if (this.useBinding && this.component.imageField) {
                src = this.boundItem[this.component.imageField];
            }
            // todo later implement variant management for images along with better extension support
            switch (this.component.mediaProxy.quality) {
                case `sm`:
                    if (this.useFallback) {
                        return src;
                    }
                    src = this.replaceExtension(src.replace(`file.`, `thumbnail-large.`), `webp`);
                    break;
                case `xs`:
                    src = this.replaceExtension(src.replace(`file.`, `thumbnail.`), `webp`);
                    if (this.useFallback) {
                        src = this.replaceExtension(src, `png`);
                    }
                    break;
            }
            return src;
        },
        containerStyle() {
            let style = this.component.getContainerCss();
            style.padding = `${this.component.mediaProxy.spacing}`;
            let spacing = parseInt(style.padding);
            if (this.component.mediaProxy.maxWidth) {
                style.maxWidth = `${this.component.mediaProxy.maxWidth + 2 * spacing}px`;
            }
            if (this.component.mediaProxy.maxHeight) {
                style.maxHeight = `${this.component.mediaProxy.maxHeight + 2 * spacing}px`;
            }
            style.borderRadius = this.component.mediaProxy.borderRadius;
            if (style.borderRadius === `50%`) {
                style.aspectRatio = `1 / 1`;
                style.height = `auto`;
            }
            return style;
        },
        imageStyle() {
            return this.component.getCss();
        }
    },
    methods: {
        replaceExtension(path, extension) {
            let names = path.split(`.`);
            if (names.length > 1) {
                const oldExtension = names[names.length - 1].toLowerCase();
                return path.replace(oldExtension, extension);
            } else {
                return path;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-component-image {
    background: var(--scheme-bg);
    width: 100%;
}

.image-component {
    height: 100%;
    width: 100%;
    max-width: 100%;
    min-height: 30px;
    margin-left: auto;
    margin-right: auto;
}
</style>
